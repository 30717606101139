import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, MutationTree, } from 'vuex';
import useRouter from "@/router";

// interfaces for our State and todos
const tabsVisible = true;
const tabsMaintenanceRepairVisible = false;
const bottomButtonVisible = false;
const bottomButtonBackgroundColor = 'background-color-3';
const bottomButtonTextColor = 'color-2';
const bottomButtonText = 'Next';
const bottomButtonPrev = false;
const bottomButtonNext = false;
const bottomButtonRouterDirection = 'forward';
const bottomButtonClick = '';
const bottomButtonId = '';
const handleBottomButtonClick = true;
export type State = {
    // show default tabs
    tabsVisible: boolean,
    // show tabs with maintenance and repair
    tabsMaintenanceRepairVisible: boolean,
    // show bottom button
    bottomButtonVisible: boolean,
    // button background color
    bottomButtonBackgroundColor: string,
    // button text color
    bottomButtonTextColor: string,
    // button text val
    bottomButtonText: string,
    // button show prev arrow
    bottomButtonPrev: boolean,
    // button show next arrow
    bottomButtonNext: boolean,
    // button router animation direction
    bottomButtonRouterDirection: string,
    // button router link
    bottomButtonClick: any,
    // button id
    bottomButtonId: string,
    // handle button click?
    handleBottomButtonClick: boolean,
};
export const key: InjectionKey<Store<State>> = Symbol();
const state: State = {
    tabsVisible: tabsVisible,
    tabsMaintenanceRepairVisible: tabsMaintenanceRepairVisible,
    bottomButtonVisible: bottomButtonVisible,
    bottomButtonBackgroundColor: bottomButtonBackgroundColor,
    bottomButtonTextColor: bottomButtonTextColor,
    bottomButtonText: bottomButtonText,
    bottomButtonPrev: bottomButtonPrev,
    bottomButtonNext: bottomButtonNext,
    bottomButtonRouterDirection: bottomButtonRouterDirection,
    bottomButtonClick: bottomButtonClick,
    bottomButtonId: bottomButtonId,
    handleBottomButtonClick: handleBottomButtonClick,
};
/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */
export const enum MUTATIONS {
    CLEAR =  'CLEAR',
    DEFAULT_TABS =  'DEFAULT_TABS',
    MAINTENANCE_REPAIR_TABS =  'MAINTENANCE_REPAIR_TABS',
    LOGIN_BUTTON =  'LOGIN_BUTTON',
    NO_BOTTOM_BAR =  'NO_BOTTOM_BAR',
    BOTTOM_BUTTON_1 =  'BOTTOM_BUTTON_1',
    BOTTOM_BUTTON_2 =  'BOTTOM_BUTTON_2',
    BOTTOM_BUTTON_3 =  'BOTTOM_BUTTON_3',
    BOTTOM_BUTTON_4 =  'BOTTOM_BUTTON_4',
    BOTTOM_BUTTON_5 =  'BOTTOM_BUTTON_5',
    BOTTOM_BUTTON_6 =  'BOTTOM_BUTTON_6',
    BOTTOM_BUTTON_7 =  'BOTTOM_BUTTON_7',
    BOTTOM_BUTTON_8 =  'BOTTOM_BUTTON_8',
    BOTTOM_BUTTON_9 =  'BOTTOM_BUTTON_9',
    BOTTOM_BUTTON_10 =  'BOTTOM_BUTTON_10',
    BOTTOM_BUTTON_11 =  'BOTTOM_BUTTON_11',
    BOTTOM_BUTTON_12 =  'BOTTOM_BUTTON_12',
    BOTTOM_BUTTON_13 =  'BOTTOM_BUTTON_13',
    BOTTOM_BUTTON_14 =  'BOTTOM_BUTTON_14',
    BOTTOM_BUTTON_15 =  'BOTTOM_BUTTON_15',
    BOTTOM_BUTTON_16 =  'BOTTOM_BUTTON_16',
    BOTTOM_BUTTON_17 =  'BOTTOM_BUTTON_17',
    BOTTOM_BUTTON_18 =  'BOTTOM_BUTTON_18',
    BOTTOM_BUTTON_19 =  'BOTTOM_BUTTON_19',
    BOTTOM_BUTTON_20 =  'BOTTOM_BUTTON_20',
    BOTTOM_BUTTON_21 =  'BOTTOM_BUTTON_21',
    BOTTOM_BUTTON_22 =  'BOTTOM_BUTTON_22',
    BOTTOM_BUTTON_23 =  'BOTTOM_BUTTON_23',
    BOTTOM_BUTTON_24 =  'BOTTOM_BUTTON_24',
    BOTTOM_BUTTON_25 =  'BOTTOM_BUTTON_25',
    BOTTOM_BUTTON_26 =  'BOTTOM_BUTTON_26',
    BOTTOM_BUTTON_27 =  'BOTTOM_BUTTON_27',
    BOTTOM_BUTTON_28 =  'BOTTOM_BUTTON_28',
    BOTTOM_BUTTON_29 =  'BOTTOM_BUTTON_29',
    BOTTOM_BUTTON_30 =  'BOTTOM_BUTTON_30',
    BOTTOM_BUTTON_31 =  'BOTTOM_BUTTON_31',
    BOTTOM_BUTTON_32 =  'BOTTOM_BUTTON_32',
}
const mutations: MutationTree<State> = {
    [MUTATIONS.CLEAR](state) {
        console.log('CLEAR');
        state.tabsVisible = true;
        state.tabsMaintenanceRepairVisible = false;
        state.bottomButtonVisible = false;
        state.bottomButtonBackgroundColor =  bottomButtonBackgroundColor;
        state.bottomButtonTextColor =  bottomButtonTextColor;
        state.bottomButtonText =  bottomButtonText;
        state.bottomButtonPrev =  bottomButtonPrev;
        state.bottomButtonNext =  bottomButtonNext;
        state.bottomButtonClick =  bottomButtonClick;
        state.bottomButtonRouterDirection =  bottomButtonRouterDirection;
        state.bottomButtonId =  bottomButtonId;
        state.handleBottomButtonClick = handleBottomButtonClick;

    },
    [MUTATIONS.DEFAULT_TABS](state) {
        console.log('DEFAULT_TABS');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = true;
    },
    [MUTATIONS.MAINTENANCE_REPAIR_TABS](state) {
        console.log('MAINTENANCE_REPAIR_TABS');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.tabsMaintenanceRepairVisible = true;
    },
    [MUTATIONS.NO_BOTTOM_BAR](state) {
        console.log('NO_BOTTOM_BAR');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
    },
    [MUTATIONS.LOGIN_BUTTON](state) {
        console.log('LOGIN_BUTTON');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Log in';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/services';
    },
    [MUTATIONS.BOTTOM_BUTTON_1](state) {
        console.log('BOTTOM_BUTTON_1');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-full-size-modal-customer-booking';
        console.log('BOTTOM_BUTTON_1_END');
    },
    [MUTATIONS.BOTTOM_BUTTON_2](state) {
        console.log('BOTTOM_BUTTON_2');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-4';
        state.bottomButtonBackgroundColor = 'background-color-15';
    },
    [MUTATIONS.BOTTOM_BUTTON_3](state) {
        console.log('BOTTOM_BUTTON_3');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Select time';
        state.bottomButtonTextColor = 'color-4';
        state.bottomButtonBackgroundColor = 'background-color-15';
    },
    [MUTATIONS.BOTTOM_BUTTON_4](state) {
        console.log('BOTTOM_BUTTON_4');

        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_5](state) {
        console.log('BOTTOM_BUTTON_5');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Book';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-full-size-modal';
        state.bottomButtonClick = '/booked-info-screen';
    },
    [MUTATIONS.BOTTOM_BUTTON_6](state) {
        console.log('BOTTOM_BUTTON_6');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Contact form';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/contact-form';
    },
    [MUTATIONS.BOTTOM_BUTTON_7](state) {
        console.log('BOTTOM_BUTTON_7');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Send';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-4';
        state.bottomButtonId = 'full-size-confirmation-model-trigger';
    },
    [MUTATIONS.BOTTOM_BUTTON_8](state) {
        console.log('BOTTOM_BUTTON_8');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'On the road';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_9](state) {
        console.log('BOTTOM_BUTTON_9');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Call customer';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_10](state) {
        console.log('BOTTOM_BUTTON_10');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Check in';
        state.bottomButtonTextColor = 'color-2';
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-7';
        state.bottomButtonClick = '/technician-service-maintenance-extracted';
    },
    [MUTATIONS.BOTTOM_BUTTON_11](state) {
        console.log('BOTTOM_BUTTON_11');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Pause or Finish Maintenance';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-maintenance-modal';
    },
    [MUTATIONS.BOTTOM_BUTTON_12](state) {
        console.log('BOTTOM_BUTTON_12');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Checkout';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-19';
        state.bottomButtonClick = '/technician-service';
    },
    [MUTATIONS.BOTTOM_BUTTON_13](state) {
        console.log('BOTTOM_BUTTON_13');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/home';
    },
    [MUTATIONS.BOTTOM_BUTTON_14](state) {
        console.log('BOTTOM_BUTTON_14');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_15](state) {
        console.log('BOTTOM_BUTTON_15');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/customer-contract';
    },
    [MUTATIONS.BOTTOM_BUTTON_16](state) {
        console.log('BOTTOM_BUTTON_16');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Confirm';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/technician-service';
    },
    [MUTATIONS.BOTTOM_BUTTON_17](state) {
        console.log('BOTTOM_BUTTON_17');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Return';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-19';
        state.bottomButtonPrev = true;
        state.bottomButtonRouterDirection = 'back';
    },
    [MUTATIONS.BOTTOM_BUTTON_18](state) {
        console.log('BOTTOM_BUTTON_18');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Check Out';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_19](state) {
        console.log('BOTTOM_BUTTON_19');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_20](state) {
        console.log('BOTTOM_BUTTON_20');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_21](state) {
        console.log('BOTTOM_BUTTON_21');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_22](state) {
        console.log('BOTTOM_BUTTON_22');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';

    },
    [MUTATIONS.BOTTOM_BUTTON_23](state) {
        console.log('BOTTOM_BUTTON_23');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_24](state) {
        console.log('BOTTOM_BUTTON_24');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Scroll down to review and accept Tasks';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_25](state) {
        console.log('BOTTOM_BUTTON_25');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept tasks';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_26](state) {
        console.log('BOTTOM_BUTTON_26');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Add photo';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.bottomButtonClick = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_27](state) {
        console.log('BOTTOM_BUTTON_27');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_28](state) {
        console.log('BOTTOM_BUTTON_28');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_29](state) {
        console.log('BOTTOM_BUTTON_29');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_30](state) {
        console.log('BOTTOM_BUTTON_30');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-7';
        state.bottomButtonClick = '/pdf-generated-screen';
    },
    [MUTATIONS.BOTTOM_BUTTON_31](state) {
        console.log('BOTTOM_BUTTON_31');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.handleBottomButtonClick = true;
        state.bottomButtonText = 'Start';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/active-service';
    },
    [MUTATIONS.BOTTOM_BUTTON_32](state) {
        console.log('BOTTOM_BUTTON_32');
        store.commit(MUTATIONS.CLEAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.handleBottomButtonClick = true;
        state.bottomButtonText = 'Create protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/create-protocol-tasks';
    },
};
/*
 * Actions
 * Perform async tasks, then mutate state
 */

export const store = createStore<State>({ state, mutations });

// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
