import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// in this way, tabs are imported when app is loaded
// other way is to import component inside routes, than it's importen during loading view'
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/intro',
  },
  {
    path: '/intro',
    redirect: '/tabs/intro',
  },
  {
    path: '/home',
    redirect: '/tabs/home',
  },
  {
    path: '/test',
    redirect: '/tabs/test',
  },
  {
    path: '/components',
    redirect: '/tabs/components',
  },
  {
    path: '/customer-booking',
    redirect: '/tabs/customer-booking',
  },
  {
    path: '/service-details',
    redirect: '/tabs/service-details',
  },
  {
    path: '/technician-service/:activeSlide',
    alias: '/technician-service',
    name: 'technician-service',
    redirect: to => {
      return { path: '/tabs/technician-service/', query: { activeSlide: to.params.activeSlide } }
    },
  },
  {
    path: '/booked-info-screen',
    redirect: '/tabs/booked-info-screen',
  },
  {
    path: '/pdf-generated-screen',
    redirect: '/tabs/pdf-generated-screen',
  },
  {
    path: '/help-and-support',
    redirect: '/tabs/help-and-support',
  },
  {
    path: '/contact-form',
    redirect: '/tabs/contact-form',
  },
  {
    path: '/take-photo',
    redirect: '/tabs/take-photo',
  },
  {
    path: '/login',
    redirect: '/tabs/login',
  },
  {
    path: '/technician-service-maintenance-extracted',
    redirect: '/tabs/technician-service-maintenance-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-maintenance-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-maintenance-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-repair-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-repair-extracted',
  },
  {
    path: '/technician-service-maintenance-notes-extracted',
    redirect: '/tabs/technician-service-maintenance-notes-extracted',
  },
  {
    path: '/technician-create-protocol',
    redirect: '/tabs/technician-create-protocol',
  },
  {
    path: '/technician-create-protocol-slides',
    redirect: '/tabs/technician-create-protocol-slides',
  },
  {
    path: '/services',
    redirect: '/tabs/services',
  },
  {
    path: '/active-service',
    redirect: '/tabs/active-service',
  },
  {
    path: '/service-finished',
    redirect: '/tabs/service-finished',
  },
  {
    path: '/create-protocol-tasks',
    redirect: '/tabs/create-protocol-tasks',
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home',
      },
      {
        path: 'intro',
        component: () => import('@/views/general/IntroPage.vue'),
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
      },
      {
        path: 'test',
        component: () => import('@/views/TestPage.vue'),
      },
      {
        path: 'components',
        component: () => import('@/views/ComponentsPage.vue'),
      },
      {
        path: 'customer-booking',
        component: () => import('@/views/customer/CustomerBooking.vue'),
      },
      {
        path: 'technician-service/:activeSlide?',
        component: () => import('@/views/technician/TechnicianService.vue'),
        props: true,
      },
      {
        path: 'booked-info-screen',
        component: () => import('@/views/general/BookedInfoScreen.vue'),
      },
      {
        path: 'help-and-support',
        component: () => import('@/views/general/HelpAndSupport.vue'),
      },
      {
        path: 'contact-form',
        component: () => import('@/views/general/ContactForm.vue'),
      },
      {
        path: 'login',
        component: () => import('@/views/general/Login.vue'),
      },
      {
        path: 'take-photo',
        component: () => import('@/views/general/TakePhoto.vue'),
      },
      {
        path: 'technician-service-maintenance-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceExtracted.vue'),
      },
      {
        path: 'technician-service-maintenance-notes-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceNotesExtracted.vue'),
      },
      {
        path: 'technician-service-maintenance-tasks-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceTasksExtracted.vue'),
      },
      {
        path: 'technician-create-protocol',
        component: () => import('@/views/technician/TechnicianCreateProtocol.vue'),
      },
      {
        path: 'technician-create-protocol-slides',
        component: () => import('@/views/technician/TechnicianCreateProtocolSlides.vue'),
      },
      {
        path: 'pdf-generated-screen',
        component: () => import('@/views/technician/PDFGeneratedScreen.vue'),
      },
      {
        path: 'services',
        component: () => import('@/views/technician/Services.vue'),
      },
      {
        path: 'service-details',
        component: () => import('@/views/technician/ServiceDetails.vue'),
      },
      {
        path: 'active-service',
        component: () => import('@/views/technician/ActiveService.vue'),
      },
      {
        path: 'service-finished',
        component: () => import('@/views/technician/ServiceFinished.vue'),
      },
      {
        path: 'create-protocol-tasks',
        component: () => import('@/views/technician/CreateProtocolTasks.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
