import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a900c24"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_item = _resolveComponent("logo-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, { "content-id": "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "bright" }, {
            default: _withCtx(() => [
              _createVNode(_component_logo_item, {
                type: _ctx.logoType,
                onNameChanged: _ctx.changeName
              }, null, 8, ["type", "onNameChanged"]),
              _createVNode(_component_ion_menu_toggle, {
                class: "menu-btn",
                slot: "end"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('@/assets/sidebar-exit.svg'),
                    alt: ""
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        class: "ion-padding",
        id: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { class: "wrapper" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            menuClose: "",
                            lines: "none",
                            onClick: _cache[0] || (_cache[0] = () => _ctx.router.push('/help-and-support'))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Help and Support")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateToPage('/home')))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Home")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            onClick: _cache[2] || (_cache[2] = () => _ctx.router.push('/components'))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Components")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            onClick: _cache[3] || (_cache[3] = () => _ctx.router.push('/technician-jobs'))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Technician jobs")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            onClick: _cache[4] || (_cache[4] = () => _ctx.router.push('/technician-service'))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Technician service")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            onClick: _cache[5] || (_cache[5] = () => _ctx.router.push('/customer-example'))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Swiper Example")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "highlighted",
                        onClick: _cache[6] || (_cache[6] = () => _ctx.router.push('/'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Logout")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    color: "medium",
                    fill: "outline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Call Us")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}