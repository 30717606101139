
import { IonItem, IonRippleEffect } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {useStore} from "@/store";
export default defineComponent({
  name: 'BottomButton',
  components: {
    IonItem,
    IonRippleEffect,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'background-color-3',
    },
    textColor: {
      type: String,
      default: 'color-2',
    },
    text: {
      type: String,
    },
    arrowPrev: {
      type: Boolean,
      default: false,
    },
    arrowNext: {
      type: Boolean,
      default: false,
    },

  },
  setup(){
    const router = useRouter();
    const store = useStore();
    return {
      router,
      store,
    }
  },
});
